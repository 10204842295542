/* eslint-disable no-unsafe-optional-chaining */ /* eslint-disable no-plusplus */ /* eslint consistent-return: 0 */ import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-consumer-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useQueryClient } from "react-query";
import { LoggerInstance, NEXT_ERR_TRANSACTION_ID, NEXT_ERR_MSG } from "@vfit/shared/providers";
import { IActionType } from "@vfit/shared/models";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { AppMobile, checkWindow, getFromLocalStorageByKey, getHome, getItemCookie, setItemCookie } from "@vfit/shared/data-access";
import { getPlaceId } from "@vfit/shared/hooks";
import { cloneDeep } from "lodash-es";
import { ErrorCodes } from "./errors";
import { CUSTOMER_TYPES } from "../api/cookie";
export var ALL_EVENTS = {
    UPDATE_PRODUCT: "updateProduct",
    UPDATE_PRODUCT_PRICE: "updateProductPrice",
    UPDATE_PRODUCT_VOUCHER: "updateProductVoucher",
    SILENT_LOGIN_COMPLETE: "silentLoginComplete",
    SILENT_LOGIN_ERROR: "silentLoginError"
};
export var IUserType;
(function(IUserType) {
    IUserType["LOGGED_USER"] = "LOGGED_USER";
    IUserType["NEXT_USER_ACTIVE"] = "NEXT_USER_ACTIVE";
    IUserType["NEXT_USER_INACTIVE"] = "NEXT_USER_INACTIVE";
    IUserType["PROSPECT_USER"] = "PROSPECT_USER";
})(IUserType || (IUserType = {}));
export var COEXISTENCE_COOKIE_KEY = "coexzr2zr7";
export var LOGGED_USER_KEYS = [
    "existingLogin",
    "newLogin",
    "newEnriched"
];
export var IN_ACTIVATION = "act";
export var TOKEN_PARTNER_OFFER = "v";
export var TOKEN_THIRD_PARTY_OFFER = "token";
export var PARTNER_OFFER_COOKIE = "ck_prtnr";
export var WIRELESS_SECURITY_TOKEN = "WIRELESS_SECURITY_TOKEN";
export var LOCKIN_OFFER_COOKIE = "ck_lk_sv";
export var USER_ABROAD_COOKIE = "ck_ab_us";
export var COOKIE_TECH_APPOINTMENT = "ck_tech_appointment";
export var COOKIE_RETRIEVE_CART = "ck_crt_addrs";
export var COOKIE_CART = "ck_crt";
export var PAYMENT_ERROR_TITLE = "pymT";
export var PAYMENT_ERROR_MESSAGE = "pymM";
export var PAYMENT_SHOW_CTC = "pymSctc";
export var FLOW_TYPE = "flowType";
export var BYPASS_CALL_API_SIA = "bcas";
export var ENABLE_MIGRATION_CODE = "enableMigrationCode";
export var SELECTED_PAYMENT = "selectedPayment";
export var TPO_TKN = "tpotk";
export var SELECTED_PAYMENT_BRAND = "selectedPaymentBrand";
export var IS_SELECTED_WALLET = "isSW";
export var PAYMENT_PRESENT_CC_BANK_ACCONT = "pymntPrsntccBnk";
export var LOCKIN_OFFER_FOUND = "lockinOfferFound";
export var START_FROM_LOCKIN = "startFromlockIn";
export var START_FROM_APP_LOCKIN = "isAppLockIn";
export var IS_AUTOMATIC_RECHARGE_SELECTED = "isAutRec";
export var PLACE_ID = "placeId";
export var OFFER_ID = "offerId";
export var CMS_ID = "cmsId";
export var DXL_JWT = "dxlJwt";
export var APP = "isApp";
export var CONTACT_ME = "contact_me";
export var ECMP = "ecmp";
export var ECMP_MAIN = "ecmpMain";
export var OTP_CHOICE = "otpChoice";
export var CF_LEGACY = "cfLegacy";
export var TECH_APPOINTMENT_REMINDER = "TECH_APPOINTMENT_REMINDER";
export var FTTH_SUB_TYPE = "FTTH_SUB_TYPE";
export var BUILDING_ID = "Id_Building";
export var IS_APPLIED_VOUCHER = "isApplV";
export var LOCK_IN_IS_ACCEPTED_FROM_HUB = "lk_is_acc";
export var CMS_TOOL_START = "cms_tool_start";
export var DXL_NBO_CAMPAIGNS = "dxlNboCampaigns";
export var DXL_NBO_CAMPAIGN = "dxlNboCampaign";
export var OFFER_PLACEID = "offer_placeId";
export var OFFER_FORMATTEDADDRESS = "offer_formattedAddress";
export var IN_TEST = "int";
export var IN_APP = "app";
export var START_PRODUCT_HUB = "startProductTechOverrideHub";
export var resetKeyOnPortabilityError = [
    "checkPortInFeasibilityIIQuery",
    "checkPortInFeasibilityIIPayload",
    "modifyPhoneNumberProductQuery", 
];
export var resetKeyListOnRecharge = [
    "fiscalCodeBirthPlace",
    "findCaller",
    "generateOTP",
    "verifyOTP",
    "postCustomer",
    "customerData",
    "customerDataCf",
    "updateProductCharacteristic",
    "updateProductCharacteristic_2",
    "updateProductCharacteristicLight",
    "validateDuplicateOrder",
    "paymentData",
    "payMean",
    "authorize",
    "authorizationError",
    "authorization",
    "ctxk",
    "associateCustomer",
    "individual",
    "deliveryDetailsIsEvolved",
    "billingAccount",
    "retrieveMultiLines",
    "multiPlayOfferingConfiguration",
    "checkPortInFeasibilityIIQuery",
    "checkPortInFeasibilityIIPayload",
    "modifyPhoneNumberProductQuery",
    "deliveryDetailsIsEditAddress",
    "linkContactAndCustomer",
    "postDeliveryServiceDetails",
    "deliveryAddress",
    "deliveryCreatedAddress",
    "billingAddress",
    "billingCreatedAddress",
    "deliveryDetailsIsBase",
    "billDeliveryDetails",
    "checkout",
    "searchPickUpLocations",
    "reservePhoneNumber",
    "deliveryDetailsIsAdvanced",
    "recognizedUser",
    CONTACT_ME,
    "email_cus",
    "phone_cus",
    "name_cus",
    "address_cus",
    "region_cus",
    "campaignsData",
    "campaignAccept",
    "lockInShoppingCartId",
    "lockinOfferFound",
    "checkCartInCookie",
    "retrieveCartFromCookie",
    "patchCustomer",
    "videoRecognition",
    "authorizationRecurring",
    "getCountry",
    "acceptCampaign",
    BYPASS_CALL_API_SIA,
    OTP_CHOICE,
    CF_LEGACY,
    IS_APPLIED_VOUCHER,
    "checkChannel",
    "checkOperator",
    "storeCmb",
    "pymT",
    "pymM",
    NEXT_ERR_TRANSACTION_ID,
    NEXT_ERR_MSG,
    START_PRODUCT_HUB, 
];
export var resetKeyListOnRechargeMobile = _to_consumable_array(resetKeyListOnRecharge).concat([
    "validatedAddress",
    "createdAddress",
    "simSelection",
    "deliveryDetailsIsUltraMobile",
    "portability",
    "deliveryDetailsIsBaseMobile",
    "deliveryDetailsIsAdvancedMobile",
    "deliveryDetailsIsEvolvedMobile",
    "deliveryDetailsIsAdvance",
    "simSelection",
    "searchPickUpStoreLocations",
    "locateStores",
    "sstps",
    "privacyProfile",
    "paymentMethod",
    "portabilityInfo",
    "customerReference",
    "modifyMobileSimDevice",
    "modifyMobileSimDeviceId",
    "oldPrivacyProfile",
    "pickupAddress",
    "pickupCreatedAddress",
    "locateStore",
    "recognition",
    "isEsim",
    "UPCAutomaticRecharge",
    "releaseResource",
    "reserve",
    ENABLE_MIGRATION_CODE,
    BYPASS_CALL_API_SIA,
    SELECTED_PAYMENT,
    PAYMENT_PRESENT_CC_BANK_ACCONT,
    OTP_CHOICE,
    CF_LEGACY, 
]);
export var allQueryKeyArray = _to_consumable_array(resetKeyListOnRecharge).concat(_to_consumable_array(resetKeyListOnRechargeMobile), _to_consumable_array([
    "isLockInIdServiceable",
    "tProd",
    "backupOffer",
    "validatedAddress",
    "offeringServiceability",
    "user",
    "techOffer",
    "isTechFWA",
    "isOutdoor",
    "createdAddress",
    "shoppingCart",
    "deleteShoppingCart",
    "persist",
    "loggedLockInFromHub",
    "startFromlockIn",
    "isApp",
    DXL_NBO_CAMPAIGNS,
    DXL_NBO_CAMPAIGN,
    "dxlCampaigns",
    START_FROM_APP_LOCKIN,
    LOCK_IN_IS_ACCEPTED_FROM_HUB,
    CMS_TOOL_START,
    "isAppMMOffer",
    "appSession",
    "startFromlockInId",
    "bcas",
    "notify",
    "isLP", 
]));
// TODO DELETE the following keys once the user submitted the order and land again in the HOMEPAGE
export var resetKeyListAfterSubmit = [
    "reserveEquipment",
    "submitOrder",
    "paymentDistribution",
    "notify",
    "portability",
    "customerReference",
    "getCountry",
    "isApp",
    DXL_NBO_CAMPAIGNS,
    DXL_NBO_CAMPAIGN,
    "dxlCampaigns",
    "appSession",
    START_FROM_APP_LOCKIN,
    "isAppMMOffer",
    NEXT_ERR_TRANSACTION_ID,
    NEXT_ERR_MSG,
    TPO_TKN, 
];
export var CART_TYPE = {
    RECURRING: "recurring",
    SHIPPING: "shipping",
    PLACEHOLDER_RECURRING: "$RECURRING",
    ONE_TIME: "oneTime",
    PLACEHOLDER_ONETIME: "$ONETIME",
    PLACEHOLDER_RECURRENCE: "$RECURRENCE",
    PLACEHOLDER_SHIPPING_PRICE: "$SHIPPING_PRICE",
    PLACEHOLDER_ORDER_ID: "$ORDER_ID",
    PLACEHOLDER_CUSTOMER_ID: "$CUSTOMER_ID"
};
export var APP_PLACEHOLDERS;
(function(APP_PLACEHOLDERS) {
    APP_PLACEHOLDERS["ACCOUNT_APP"] = "$ACCOUNT_APP";
    APP_PLACEHOLDERS["MSISDN_APP"] = "$MSISDN_APP";
    APP_PLACEHOLDERS["CHECKSUM_APP"] = "$CHECK_APP";
})(APP_PLACEHOLDERS || (APP_PLACEHOLDERS = {}));
export var ENRG_QSTRING;
(function(ENRG_QSTRING) {
    ENRG_QSTRING["MSISDN"] = "m";
    ENRG_QSTRING["ACCOUNT"] = "a";
    ENRG_QSTRING["CHECKSUM"] = "c";
})(ENRG_QSTRING || (ENRG_QSTRING = {}));
export var errorMock = function(apiCall, error, errorType, errorCode, errorMessage, isBlocking, otherInfo) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15;
    var lastDexTransaction = (localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem(NEXT_ERR_TRANSACTION_ID)) || "";
    var lastErrorMessage = (localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem(NEXT_ERR_MSG)) || "";
    return _object_spread({
        error: {
            url: ((ref3 = (ref2 = error === null || error === void 0 ? void 0 : (ref = error.url) === null || ref === void 0 ? void 0 : ref.substring(error.url.lastIndexOf("/") + 1, error === null || error === void 0 ? void 0 : (ref1 = error.url) === null || ref1 === void 0 ? void 0 : ref1.length)) === null || ref2 === void 0 ? void 0 : ref2.split("?")) === null || ref3 === void 0 ? void 0 : ref3[0]) || apiCall || "",
            status: (error === null || error === void 0 ? void 0 : error.status) || "timeout",
            statusText: (error === null || error === void 0 ? void 0 : error.statusText) || "timeout"
        },
        errorMessage: errorMessage || "Error occurred in ".concat(apiCall, " API service"),
        errorType: errorType,
        errorCode: errorCode
    }, ((error === null || error === void 0 ? void 0 : (ref4 = error.headers) === null || ref4 === void 0 ? void 0 : ref4.get("dex-transaction-id")) || lastDexTransaction) && {
        transaction_id: (error === null || error === void 0 ? void 0 : (ref5 = error.headers) === null || ref5 === void 0 ? void 0 : ref5.get("dex-transaction-id")) || lastDexTransaction || ""
    }, (error === null || error === void 0 ? void 0 : (ref6 = error.headers) === null || ref6 === void 0 ? void 0 : ref6.get("err-backenderrorcode")) && {
        err_backendErrorCode: (error === null || error === void 0 ? void 0 : (ref7 = error.headers) === null || ref7 === void 0 ? void 0 : ref7.get("err-backenderrorcode")) || ""
    }, (error === null || error === void 0 ? void 0 : (ref8 = error.headers) === null || ref8 === void 0 ? void 0 : ref8.get("err-backenderrormessage")) && {
        err_backendErrorMessage: (error === null || error === void 0 ? void 0 : (ref9 = error.headers) === null || ref9 === void 0 ? void 0 : ref9.get("err-backenderrormessage")) || ""
    }, (error === null || error === void 0 ? void 0 : (ref10 = error.headers) === null || ref10 === void 0 ? void 0 : ref10.get("err-backendsource")) && {
        err_backendSource: (error === null || error === void 0 ? void 0 : (ref11 = error.headers) === null || ref11 === void 0 ? void 0 : ref11.get("err-backendsource")) || ""
    }, (error === null || error === void 0 ? void 0 : (ref12 = error.headers) === null || ref12 === void 0 ? void 0 : ref12.get("err-errorcode")) && {
        err_errorCode: (error === null || error === void 0 ? void 0 : (ref13 = error.headers) === null || ref13 === void 0 ? void 0 : ref13.get("err-errorcode")) || ""
    }, ((error === null || error === void 0 ? void 0 : (ref14 = error.headers) === null || ref14 === void 0 ? void 0 : ref14.get("err-errormessage")) || lastErrorMessage) && {
        err_errorMessage: (error === null || error === void 0 ? void 0 : (ref15 = error.headers) === null || ref15 === void 0 ? void 0 : ref15.get("err-errormessage")) || lastErrorMessage || ""
    }, isBlocking && {
        isBlocking: isBlocking
    }, otherInfo && {
        otherInfo: otherInfo
    });
};
export var ALTERNATIVEOFFERIDKEY = "alternativeOffer";
export var ALTERNATIVE_OFFER_SHOW_MESSAGE_IDKEY = "alternativeOfferShowMessage";
export var BACKUP_OFFER_KEY = "backupOffer";
// -IMPRO
export var useGetDataFromStorage = function(key, disableLocalStorage) {
    if (checkWindow()) {
        // eslint-disable-next-line
        var ref = useState(undefined), dataState = ref[0], setDataState = ref[1];
        // eslint-disable-next-line
        var queryClient = useQueryClient();
        LoggerInstance.debug("getDataFromStorage key : ", key);
        var dataFromQuery = queryClient.getQueryData(key);
        var dataFromStorage = !disableLocalStorage && !!localStorage && localStorage.getItem(key);
        var dataFromStorageRetrieved = !!dataFromStorage && dataFromStorage !== "undefined" ? JSON.parse(dataFromStorage) : undefined;
        // eslint-disable-next-line
        useEffect(function() {
            LoggerInstance.debug("getDataFromStorage dataFromQuery ", dataFromQuery);
            LoggerInstance.debug("GET COUNTRY VALUE - storage : ", dataFromStorage);
            if (dataFromQuery) {
                LoggerInstance.debug("QUERY WINS");
                setDataState(dataFromQuery);
            } else if (dataFromStorage) {
                LoggerInstance.debug("STORAGE WINS");
                // eslint-disable-next-line
                dataFromStorageRetrieved && setDataState(dataFromStorageRetrieved);
                LoggerInstance.debug("getDataFromStorage dataFromStorageRetrieved : ", dataFromStorageRetrieved);
            }
        // eslint-disable-next-line
        }, []); // !!dataFromQuery && !!dataFromStorage
        if (!!dataFromQuery || !!dataFromStorage || !!dataState) return dataFromQuery || dataFromStorageRetrieved || dataState;
        // return undefined
        LoggerInstance.debug("getDataFromStorage dataState : ", dataState);
    // return  dataState || dataFromQuery || dataFromStorageRetrieved
    }
};
var getUrlExtension = function(url) {
    var ref, ref1, ref2, ref3;
    return ((ref3 = (ref2 = (ref = url === null || url === void 0 ? void 0 : url.split(/[#?]/)) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.split(".")) === null || ref2 === void 0 ? void 0 : ref2.pop()) === null || ref3 === void 0 ? void 0 : ref3.trim()) || "";
};
export var downloadFileFunction = function(url) {
    var urlExt = getUrlExtension(url);
    if (urlExt !== "pdf") {
        window.open(url, "_blank");
    } else {
        window.open(url, "_blank");
    // fetch(url, {
    //   method: 'GET',
    // })
    //   .then((resp) => resp.blob())
    //   .then((blob) => {
    //     const urlLocal = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.style.display = 'none';
    //     a.href = urlLocal;
    //     a.download = name;
    //     document.body.appendChild(a);
    //     a.click();
    //     window.URL.revokeObjectURL(urlLocal);
    //   });
    }
};
/**
 *  For each element contained in the array data, it adds an N number of asterisks
 *  to the beginning or end of each item.
 *  If asterisksQt is a positive number, it adds them to the beginning,
 *  if negative it adds them to the end.
 * @param data
 * @param asterisksQt
 * @returns
 */ export var maskData = function(data, asterisksQt) {
    /**
   *
   * @param items
   * @param mode
   * @param asteriskQt
   * @returns
   */ var handlerDataReplace = function(items, mode, asteriskQt) {
        return items.map(function(item) {
            return item.replace(mode, asteriskQt);
        });
    };
    /**
   *
   * @param asteriskLength
   * @returns
   */ var getAsterisk = function(asteriskLength) {
        var asteriskQt = "";
        for(var index = 0; index < asteriskLength; index += 1){
            asteriskQt += "*";
        }
        return asteriskQt;
    };
    var fieldsWithMask = [];
    if (asterisksQt > 0) {
        var asterisk = getAsterisk(asterisksQt);
        // eslint-disable-next-line prefer-template
        var firstDigits = new RegExp("^.{" + asterisksQt + "}");
        fieldsWithMask = handlerDataReplace(data, firstDigits, asterisk);
    }
    if (asterisksQt < 0) {
        LoggerInstance.debug("asterisksQt", asterisksQt);
        var positiveNumber = asterisksQt * -1;
        var asterisk1 = getAsterisk(positiveNumber);
        // eslint-disable-next-line prefer-template
        var lastDigits = new RegExp("(.{" + positiveNumber + "})s*$");
        fieldsWithMask = handlerDataReplace(data, lastDigits, asterisk1);
    }
    return fieldsWithMask;
};
export var getRedirectCoverage = function(redirectCoveragePath) {
    var home = getHome();
    if (!redirectCoveragePath) {
        window.location.href = "/";
    }
    return "".concat(home, "/").concat(redirectCoveragePath);
};
export var getFormattedPriceString = function(price, recurrence) {
    var priceToReturn = price;
    if (recurrence) {
        if ((price === null || price === void 0 ? void 0 : price.toLowerCase()) === "gratis") priceToReturn += "<span class='rec'> ".concat(recurrence, "</span>");
        else if (recurrence[0] === "/") priceToReturn += "<span class='rec'>".concat(recurrence, "</span>");
        else priceToReturn += "<span class='rec'>/".concat(recurrence, "</span>");
    }
    return priceToReturn;
};
export var retrieveCustomerData = function() {
    var customerSaved = localStorage.getItem("customerData");
    var customerData = !!customerSaved && customerSaved !== "undefined" && JSON.parse(customerSaved);
    return customerData;
};
export var retrieveProduct = function() {
    var isReset = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    var customerData = retrieveCustomerData();
    var tProd_backUp = localStorage.getItem("tProd_backUp");
    if (isReset && (!customerData || customerData && customerData.length === 0) && tProd_backUp && tProd_backUp !== "undefined") {
        LoggerInstance.debug("retrieveProduct tProd_backUp ", tProd_backUp);
        localStorage.setItem("tProd", tProd_backUp);
        localStorage.removeItem("tProd_backUp");
        return tProd_backUp ? JSON.parse(tProd_backUp) : null;
    }
    var tProd = localStorage.getItem("tProd");
    return tProd && tProd !== "undefined" ? JSON.parse(tProd) : null;
};
export var productSpecificError = function(prod) {
    var ref, ref1, ref2, ref3;
    return {
        buttonPaymentError: (prod === null || prod === void 0 ? void 0 : (ref = prod.paymentErrorCallMeNow) === null || ref === void 0 ? void 0 : ref.label) || "TI RICHIAMIAMO NOI",
        urlPaymentErrorCallMeNow: (prod === null || prod === void 0 ? void 0 : (ref1 = prod.paymentErrorCallMeNow) === null || ref1 === void 0 ? void 0 : ref1.url) || "https://assistenza.vodafone.it/cmb/entrypoint/?skill=adsl_4&DESC=WEB_payment-error__NOABTEST_ORG_checkout_fixed_unlimited_2790",
        buttonGenericError: (prod === null || prod === void 0 ? void 0 : (ref2 = prod.genericErrorCallMeNow) === null || ref2 === void 0 ? void 0 : ref2.label) || "TI RICHIAMIAMO NOI",
        urlGenericErrorCallMeNow: (prod === null || prod === void 0 ? void 0 : (ref3 = prod.genericErrorCallMeNow) === null || ref3 === void 0 ? void 0 : ref3.url) || "https://assistenza.vodafone.it/cmb/entrypoint/?skill=adsl_4&DESC=WEB_Generic-error__NOABTEST_ORG_checkout_fixed_unlimited_2790"
    };
};
export var retrieveShoppingCart = function() {
    var shoppingCartSaved = localStorage.getItem("shoppingCart");
    return shoppingCartSaved ? JSON.parse(shoppingCartSaved) : null;
};
export var retrieveProductTechnology = function() {
    var ref;
    var productTechnology = (ref = localStorage.getItem("techOffer")) === null || ref === void 0 ? void 0 : ref.replace(/["']/g, "");
    if (productTechnology) {
        return productTechnology;
    }
};
export var updateProduct = function(product) {
    return localStorage.setItem("tProd", JSON.stringify(product));
};
export var setPersistData = function() {
    return localStorage.setItem("persist", "1");
};
export var getPersistData = function() {
    return localStorage.getItem("persist");
};
export var deleteLoggedLockInFromHub = function() {
    return localStorage.removeItem("loggedLockInFromHub");
};
export var setIsStartFromLockIn = function() {
    return localStorage.setItem("startFromlockIn", "1");
};
export var getIsStartFromLockin = function() {
    return localStorage.getItem("startFromlockIn");
};
export var deleteIsStartFromLockin = function() {
    return localStorage.removeItem("startFromlockIn");
};
export var setStartFromLockInId = function(value) {
    return localStorage.setItem("startFromlockInId", value);
};
export var deleteIsLockInIdServiceable = function() {
    return localStorage.removeItem("isLockInIdServiceable");
};
export var setIsLockInIdServiceable = function() {
    return localStorage.setItem("isLockInIdServiceable", "1");
};
export var isLockInIdServiceable = function() {
    return localStorage.getItem("isLockInIdServiceable") === "1";
};
export var deleteStartFromLockinId = function() {
    return localStorage.removeItem("startFromlockInId");
};
export var isPersistData = function() {
    var ref;
    var persistData = getPersistData();
    var queryString = window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.search;
    return (queryString === null || queryString === void 0 ? void 0 : queryString.includes(ErrorCodes.MODE_BACK_SIA)) || (queryString === null || queryString === void 0 ? void 0 : queryString.includes(ErrorCodes.MODE_BACK_SIA_INSTANT)) || (queryString === null || queryString === void 0 ? void 0 : queryString.includes(ErrorCodes.MODE_DONE_SIA_INSTANT)) || queryString.includes(ErrorCodes.MODE_BACK_SIA_RECURRENT) || queryString.includes(ErrorCodes.MODE_DONE_SIA_RECURRENT) || queryString.includes(ErrorCodes.URL_FORWARD) || !!persistData;
};
export var isErrorOnPayment = function() {
    var ref;
    var queryString = window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.search;
    return queryString === null || queryString === void 0 ? void 0 : queryString.includes(ErrorCodes.ERROR_ON_PAYMENT);
};
export var isInResetUser = function() {
    var ref;
    var queryString = window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.search;
    return queryString === null || queryString === void 0 ? void 0 : queryString.includes(ErrorCodes.MODE_RESET_USER);
};
export var getSelectedOfferIds = function() {
    var backupOfferKey = getFromLocalStorageByKey(BACKUP_OFFER_KEY);
    return {
        cmsId: backupOfferKey === null || backupOfferKey === void 0 ? void 0 : backupOfferKey.cmsId,
        offerId: backupOfferKey === null || backupOfferKey === void 0 ? void 0 : backupOfferKey.offeringId
    };
};
export var getOneTimeAmountForUpfront = function(includeShipping) {
    var ref, ref1;
    var shoppingCart = retrieveShoppingCart();
    if (!shoppingCart) return;
    var cartTotalPrice = shoppingCart.cartTotalPrice;
    var cartTotalPrices = cartTotalPrice === null || cartTotalPrice === void 0 ? void 0 : cartTotalPrice.filter(function(cart) {
        return cart.type === CART_TYPE.ONE_TIME;
    });
    var oneTimePrice = cartTotalPrices === null || cartTotalPrices === void 0 ? void 0 : cartTotalPrices.find(function(cart) {
        return !cart.isNonImmediateOC && !(cart === null || cart === void 0 ? void 0 : cart.isOnInvoiceOC) && !(cart === null || cart === void 0 ? void 0 : cart.isTotalPriceOC);
    });
    var totalPrice = oneTimePrice === null || oneTimePrice === void 0 ? void 0 : (ref = oneTimePrice.price) === null || ref === void 0 ? void 0 : ref.taxIncludedAmount;
    // retrieving the shippingCost and adding to the totalPrice if present
    if (includeShipping) {
        var shippingCost = getCostFromShoppingCartByType(CART_TYPE.SHIPPING);
        if (shippingCost.price) totalPrice += shippingCost.price;
    }
    var totalPriceParsed = (ref1 = Number(totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.toString()).toFixed(2)) === null || ref1 === void 0 ? void 0 : ref1.replace(".", ",");
    return totalPriceParsed == "0,00" ? 0 : totalPriceParsed;
};
export var getOneTimeAmountFromShoppingCart = function() {
    var ref, ref1, ref2, ref3;
    var shoppingCart = retrieveShoppingCart();
    if (!shoppingCart) return;
    var cartTotalPrice = shoppingCart.cartTotalPrice;
    var cartTotalPrices = cartTotalPrice === null || cartTotalPrice === void 0 ? void 0 : cartTotalPrice.filter(function(cart) {
        return cart.type === CART_TYPE.ONE_TIME;
    });
    var oneTimePrice = (cartTotalPrices === null || cartTotalPrices === void 0 ? void 0 : cartTotalPrices.length) > 0 ? cartTotalPrices[cartTotalPrices.length - 1] : null;
    var considerIsNonImmediateOC = cartTotalPrices === null || cartTotalPrices === void 0 ? void 0 : cartTotalPrices.find(function(el) {
        return el.isNonImmediateOC;
    });
    if (considerIsNonImmediateOC) oneTimePrice = considerIsNonImmediateOC;
    var amount = (oneTimePrice === null || oneTimePrice === void 0 ? void 0 : (ref = oneTimePrice.price) === null || ref === void 0 ? void 0 : ref.taxIncludedAmount) || 0;
    var orginalAmount = (oneTimePrice === null || oneTimePrice === void 0 ? void 0 : (ref1 = oneTimePrice.originalprice) === null || ref1 === void 0 ? void 0 : ref1.taxIncludedAmount) || 0;
    var amountParsed = (ref2 = Number(amount === null || amount === void 0 ? void 0 : amount.toString()).toFixed(2)) === null || ref2 === void 0 ? void 0 : ref2.replace(".", ",");
    var originalAmountParsed = (ref3 = Number(orginalAmount === null || orginalAmount === void 0 ? void 0 : orginalAmount.toString()).toFixed(2)) === null || ref3 === void 0 ? void 0 : ref3.replace(".", ",");
    return {
        formattedPrice: amountParsed == "0,00" ? 0 : amountParsed,
        price: amount,
        originalFormattedPrice: originalAmountParsed == "0,00" ? 0 : originalAmountParsed,
        originalPrice: orginalAmount
    };
};
export var getCostFromShoppingCartByType = function(type) {
    var ref, ref1, ref2, ref3;
    var shoppingCart = retrieveShoppingCart();
    if (!shoppingCart) return;
    var cartTotalPrice = shoppingCart.cartTotalPrice;
    var cartTotalPrices = cartTotalPrice === null || cartTotalPrice === void 0 ? void 0 : cartTotalPrice.filter(function(cart) {
        return cart.type === type;
    });
    var recurringPrice = (cartTotalPrices === null || cartTotalPrices === void 0 ? void 0 : cartTotalPrices.length) > 0 ? cartTotalPrices[cartTotalPrices.length - 1] : null;
    var amount = (recurringPrice === null || recurringPrice === void 0 ? void 0 : (ref = recurringPrice.price) === null || ref === void 0 ? void 0 : ref.taxIncludedAmount) || 0;
    var orginalAmount = (recurringPrice === null || recurringPrice === void 0 ? void 0 : (ref1 = recurringPrice.originalprice) === null || ref1 === void 0 ? void 0 : ref1.taxIncludedAmount) || 0;
    var amountParsed = (ref2 = Number(amount === null || amount === void 0 ? void 0 : amount.toString()).toFixed(2)) === null || ref2 === void 0 ? void 0 : ref2.replace(".", ",");
    var originalAmountParsed = (ref3 = Number(orginalAmount === null || orginalAmount === void 0 ? void 0 : orginalAmount.toString()).toFixed(2)) === null || ref3 === void 0 ? void 0 : ref3.replace(".", ",");
    return {
        formattedPrice: amountParsed == "0,00" ? 0 : amountParsed,
        price: amount,
        originalFormattedPrice: originalAmountParsed == "0,00" ? 0 : originalAmountParsed,
        originalPrice: orginalAmount
    };
};
export var retrieveLockInOfferFromStorage = function() {
    var lockinOfferFoundSaved = localStorage.getItem("lockinOfferFound");
    var lockinOfferFound = lockinOfferFoundSaved !== "undefined" && JSON.parse(lockinOfferFoundSaved);
    LoggerInstance.debug("LockIn - retrieveAllProdutcs lockinOfferFound: ", lockinOfferFound);
    return lockinOfferFound;
};
export var isInABTest = function() {
    if (!checkWindow()) {
        return false;
    }
    var searchParams = window.location && window.location.href ? new URL(window.location.href).searchParams : "";
    return searchParams ? (searchParams === null || searchParams === void 0 ? void 0 : searchParams.get(IN_TEST)) === "1" || false : false;
};
export var getParamsInUrl = function(key) {
    if (!checkWindow()) {
        return "";
    }
    var searchParams = window.location && window.location.href ? new URL(window.location.href).searchParams : "";
    return searchParams ? (searchParams === null || searchParams === void 0 ? void 0 : searchParams.get(key)) || "" : "";
};
export var isInApp = function() {
    if (!checkWindow()) {
        return false;
    }
    var searchParams = window.location && window.location.href ? new URL(window.location.href).searchParams : "";
    return searchParams ? (searchParams === null || searchParams === void 0 ? void 0 : searchParams.get(IN_APP)) === "true" || false : false;
};
export var retrieveInTest = function() {
    return !!localStorage.getItem(IN_TEST);
};
export var retrieveInTestByOffer = function() {
    var product = retrieveProduct();
    return (product === null || product === void 0 ? void 0 : product.enableAbTest) && retrieveInTest();
};
export var isMobileProduct = function() {
    var ref;
    var product = retrieveProduct();
    if (product === null || product === void 0 ? void 0 : (ref = product.action) === null || ref === void 0 ? void 0 : ref.type) return product.action.type.toString() === IActionType.CHECKOUT_MODAL_MOBILE.toString() || false;
    return false;
};
export var getCurrentUserType = function() {
    var ref;
    var silentLogin = getFromLocalStorageByKey("silentLogin");
    var findCaller = getFromLocalStorageByKey("findCaller");
    var isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin === null || silentLogin === void 0 ? void 0 : silentLogin.flowId);
    if (isLoggedUser) {
        return IUserType.LOGGED_USER;
    }
    if ((findCaller === null || findCaller === void 0 ? void 0 : findCaller.resultInfo) === "existingNextCustomer" && (findCaller === null || findCaller === void 0 ? void 0 : (ref = findCaller.customerRef) === null || ref === void 0 ? void 0 : ref.id)) {
        if (findCaller.customerHasActiveSubs) return IUserType.NEXT_USER_ACTIVE;
        return IUserType.NEXT_USER_INACTIVE;
    }
    return IUserType.PROSPECT_USER;
};
export var recognizedUser = function() {
    var ref;
    var findCallerSaved = localStorage.getItem("findCaller");
    var silentLoginSaved = localStorage.getItem("silentLogin");
    var silentLogin = !!silentLoginSaved && silentLoginSaved !== "undefined" && JSON.parse(silentLoginSaved);
    var isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin === null || silentLogin === void 0 ? void 0 : silentLogin.flowId);
    var findCaller = !!findCallerSaved && findCallerSaved !== "undefined" && JSON.parse(findCallerSaved);
    return isLoggedUser || (findCaller === null || findCaller === void 0 ? void 0 : findCaller.customerHasActiveSubs) && (findCaller === null || findCaller === void 0 ? void 0 : findCaller.resultInfo) === "existingNextCustomer" && (findCaller === null || findCaller === void 0 ? void 0 : (ref = findCaller.customerRef) === null || ref === void 0 ? void 0 : ref.id);
};
export var handleFirstCharacterOfSentence = function(textToFormat) {
    var ref, ref1, ref2;
    return (ref2 = (ref1 = (ref = textToFormat === null || textToFormat === void 0 ? void 0 : textToFormat.toLowerCase()) === null || ref === void 0 ? void 0 : ref.split(" ")) === null || ref1 === void 0 ? void 0 : ref1.map(function(text) {
        return text.charAt(0).toUpperCase() + text.substring(1);
    })) === null || ref2 === void 0 ? void 0 : ref2.join(" ");
};
/**
 * Check if user is R02
 */ export var isR02User = function(coexistenceCookie) {
    if (coexistenceCookie) {
        var ref;
        var cookieVal = coexistenceCookie && ((ref = coexistenceCookie) === null || ref === void 0 ? void 0 : ref.split("_")[2].trim()) || "";
        if (cookieVal === "01" || cookieVal === "02") {
            return true;
        }
    }
    var coexistence = localStorage.getItem("coexistenceUser");
    var coexistenceData = !!coexistence && coexistence !== "undefined" && JSON.parse(coexistence);
    LoggerInstance.debug("R02", coexistenceData);
    if (coexistenceData) {
        return (coexistenceData === null || coexistenceData === void 0 ? void 0 : coexistenceData.trustCookie) === "yes" && (coexistenceData === null || coexistenceData === void 0 ? void 0 : coexistenceData.userType) === CUSTOMER_TYPES.LEGACY;
    }
    return false;
};
export var replaceShippingCost = function(label) {
    var price = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "";
    return (label === null || label === void 0 ? void 0 : label.replace(CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, price)) || "";
};
export var getCustomerIdentification = function(owningIndividual) {
    var identifications = (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.identification) || [];
    if (!identifications || identifications.length === 0) return null;
    return identifications.length > 0 ? identifications[identifications.length - 1] : identifications[0];
};
/**
 * Method to get the customer country
 * @param owningData
 */ export var getCustomerCountry = function(owningData) {
    var ref;
    var countries = getFromLocalStorageByKey("getCountry");
    var identification = getCustomerIdentification(owningData.owningIndividual);
    return (ref = countries) === null || ref === void 0 ? void 0 : ref.filter(function(countryObj) {
        return (identification === null || identification === void 0 ? void 0 : identification.nationality) === countryObj.displayName;
    });
};
/**
 * Method to get the customer document
 * @param owningData
 */ export var getCustomerDocument = function(owningData) {
    var ref;
    var customerReference = getFromLocalStorageByKey("customerReference");
    var identification = getCustomerIdentification(owningData.owningIndividual);
    return customerReference === null || customerReference === void 0 ? void 0 : (ref = customerReference.identificationType) === null || ref === void 0 ? void 0 : ref.filter(function(documentObj) {
        return (identification === null || identification === void 0 ? void 0 : identification.identificationType) === documentObj.displayName;
    });
};
/**
 * This method is used to check if the edited address from the frontend has all field filled.
 * @param address1 check if all fields are not empty
 * @param address2 check if we have some differences from the previous setted address
 * @returns boolean enabler for shipping or billing flow
 */ export var isDifferentAddress = function(address1, address2) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
    return (address1 === null || address1 === void 0 ? void 0 : address1.city) && (address1 === null || address1 === void 0 ? void 0 : address1.postalCode) && (address1 === null || address1 === void 0 ? void 0 : address1.stateOrProvince) && (address1 === null || address1 === void 0 ? void 0 : address1.street) && (address1 === null || address1 === void 0 ? void 0 : address1.streetNumber) && ((address1 === null || address1 === void 0 ? void 0 : address1.city.toUpperCase()) !== ((ref = address2) === null || ref === void 0 ? void 0 : (ref1 = ref.city) === null || ref1 === void 0 ? void 0 : ref1.toUpperCase()) || (address1 === null || address1 === void 0 ? void 0 : address1.postalCode.toUpperCase()) !== ((ref2 = address2) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.postalCode) === null || ref3 === void 0 ? void 0 : ref3.toUpperCase()) || (address1 === null || address1 === void 0 ? void 0 : address1.stateOrProvince.toUpperCase()) !== ((ref4 = address2) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.stateOrProvince) === null || ref5 === void 0 ? void 0 : ref5.toUpperCase()) || (address1 === null || address1 === void 0 ? void 0 : address1.street.toUpperCase()) !== ((ref6 = address2) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.street) === null || ref7 === void 0 ? void 0 : ref7.toUpperCase()) || (address1 === null || address1 === void 0 ? void 0 : address1.streetNumber.toUpperCase()) !== ((ref8 = address2) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.streetNumber) === null || ref9 === void 0 ? void 0 : ref9.toUpperCase()));
};
/**
 * Method to check if is go back SIA
 */ export var checkIsGoBackSIA = function() {
    var ref;
    if (!checkWindow()) return false;
    var queryString = window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.search;
    return queryString.includes(ErrorCodes.ERROR_ON_PAYMENT) || queryString.includes(ErrorCodes.MODE_BACK_SIA_INSTANT) || queryString.includes(ErrorCodes.MODE_BACK_SIA_RECURRENT) || queryString.includes(ErrorCodes.MODE_DONE_SIA_RECURRENT) || queryString.includes(ErrorCodes.MODE_BACK_SIA) || queryString.includes(ErrorCodes.MODE_DONE_SIA_INSTANT);
};
export var checkIsApp = function() {
    return AppMobile.checkIsApp();
};
/**
 * Return true if is come from SIA
 */ export var checkBypassApiCallFromSIA = function() {
    if (!checkWindow()) return false;
    return !!localStorage.getItem(BYPASS_CALL_API_SIA);
};
/**
 * Method to get last payment method for paymean
 * @param payMean
 * @param type
 */ export var getLastPaymentMethod = function(payMean, type) {
    var ref;
    if (!payMean || !(payMean === null || payMean === void 0 ? void 0 : payMean.payMeans)) return;
    if ((payMean === null || payMean === void 0 ? void 0 : (ref = payMean.payMeans) === null || ref === void 0 ? void 0 : ref.length) === 0) return;
    var lastSelectedType = localStorage.getItem(SELECTED_PAYMENT) || type || "";
    if (lastSelectedType) {
        var ref1;
        var filteredPaymeans = (ref1 = payMean.payMeans) === null || ref1 === void 0 ? void 0 : ref1.filter(function(el) {
            return el.type.toLowerCase() === lastSelectedType.toLowerCase();
        });
        if (filteredPaymeans === null || filteredPaymeans === void 0 ? void 0 : filteredPaymeans[0]) return filteredPaymeans[0];
        return payMean.payMeans[0];
    }
    // the last payment method is not the last but the first
    // return payMean.payMeans[payMean.payMeans.length - 1];
    return payMean.payMeans[0];
};
export var organizeCMSErrors = function(errorsFromCms) {
    var ref, ref1;
    LoggerInstance.debug("CMSErrors", errorsFromCms);
    var errorcomponent = (errorsFromCms || {}).errorcomponent;
    if (!errorcomponent) {
        return {
            redirectToShoppingCartErrorOnPayment: [
                {
                    error: "hardko"
                },
                {
                    error: "softko"
                },
                {
                    error: "timeouthardko"
                },
                {
                    error: "timeoutSoftKo"
                },
                {
                    error: "paymeanalreadyexist"
                },
                {
                    error: "paymeanalreadyexistforsamebankcode"
                },
                {
                    error: "mandaterejectedorcancelled"
                },
                {
                    error: "ibanvalidationfailed"
                },
                {
                    error: "timeoutProcessing"
                }, 
            ],
            blockingErrors: [
                {
                    error: "ERROR_ON_PAYMENT_GENERIC",
                    title: "",
                    message: "Si \xe8 verificato un errore, riprovare in seguito"
                },
                {
                    error: ErrorCodes.AUTHORIZATION_ERROR_KO,
                    title: "",
                    message: "Si \xe8 verificato un errore, riprovare in seguito"
                },
                {
                    error: ErrorCodes.PAYMENT_DISTRIBUTION_ERROR,
                    title: "",
                    message: "Si \xe8 verificato un errore, riprovare in seguito"
                },
                {
                    error: ErrorCodes.RESERVE_EQUIPMENT_ERROR,
                    title: "",
                    message: "Si \xe8 verificato un errore, riprovare in seguito"
                },
                {
                    error: ErrorCodes.MODIFY_PHONE_NUMBER_PRODUCT_ERROR,
                    title: "",
                    message: "Si \xe8 verificato un errore, riprovare in seguito"
                }, 
            ]
        };
    }
    var mapErrors = function(errors) {
        return errors === null || errors === void 0 ? void 0 : errors.map(function(error) {
            return _object_spread_props(_object_spread({}, error), {
                isBlocking: (error === null || error === void 0 ? void 0 : error.isClickToCall) || "false",
                title: (error === null || error === void 0 ? void 0 : error.title) || (errorcomponent === null || errorcomponent === void 0 ? void 0 : errorcomponent.defaultTitle),
                message: (error === null || error === void 0 ? void 0 : error.message) || (errorcomponent === null || errorcomponent === void 0 ? void 0 : errorcomponent.defaultMessage)
            });
        });
    };
    return {
        redirectToShoppingCartErrorOnPayment: mapErrors(errorcomponent === null || errorcomponent === void 0 ? void 0 : (ref = errorcomponent.errors) === null || ref === void 0 ? void 0 : ref.filter(function(error) {
            return !error.isClickToCall || (error === null || error === void 0 ? void 0 : error.isClickToCall) === "false";
        })),
        blockingErrors: mapErrors(errorcomponent === null || errorcomponent === void 0 ? void 0 : (ref1 = errorcomponent.errors) === null || ref1 === void 0 ? void 0 : ref1.filter(function(error) {
            return (error === null || error === void 0 ? void 0 : error.isClickToCall) === "true";
        }))
    };
};
/**
 * Method to return the blocking and the back's errors
 * @param orderErrors
 */ export var getBackAndBlockinErrors = function(orderErrors) {
    var ref = organizeCMSErrors(orderErrors), redirectToShoppingCartErrorOnPayment = ref.redirectToShoppingCartErrorOnPayment, blockingErrors = ref.blockingErrors;
    var goBackErrorOnPayment = redirectToShoppingCartErrorOnPayment.map(function(errorLocal) {
        var ref;
        return errorLocal === null || errorLocal === void 0 ? void 0 : (ref = errorLocal.error) === null || ref === void 0 ? void 0 : ref.toLowerCase();
    });
    var blockErrors = blockingErrors.map(function(errorLocal) {
        var ref;
        return errorLocal === null || errorLocal === void 0 ? void 0 : (ref = errorLocal.error) === null || ref === void 0 ? void 0 : ref.toLowerCase();
    });
    return {
        goBackErrorOnPayment: redirectToShoppingCartErrorOnPayment,
        goBackErrorOnPaymentFormattedErrors: goBackErrorOnPayment,
        blockingFormattedError: blockErrors,
        blockingErrors: blockingErrors
    };
};
export var getCompleteAddressValidation = function(coverageToolConfig) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11;
    return yup.object({
        placeId: yup.string().required(""),
        city: yup.string().min(1, (coverageToolConfig === null || coverageToolConfig === void 0 ? void 0 : (ref = coverageToolConfig.coveragetool) === null || ref === void 0 ? void 0 : (ref1 = ref.errorMessage) === null || ref1 === void 0 ? void 0 : ref1.error0) || " ").matches(/^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ'"+\s]*$/g, (coverageToolConfig === null || coverageToolConfig === void 0 ? void 0 : (ref2 = coverageToolConfig.coveragetool) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.errorMessage) === null || ref3 === void 0 ? void 0 : ref3.error1) || " "),
        //  .required(coverageToolConfig.coveragetool.errorMessage.error0),
        street: yup.string().min(1, (coverageToolConfig === null || coverageToolConfig === void 0 ? void 0 : (ref4 = coverageToolConfig.coveragetool) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.errorMessage) === null || ref5 === void 0 ? void 0 : ref5.error0) || " ").matches(/^[a-zA-Z+"-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g, (coverageToolConfig === null || coverageToolConfig === void 0 ? void 0 : (ref6 = coverageToolConfig.coveragetool) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.errorMessage) === null || ref7 === void 0 ? void 0 : ref7.error1) || " "),
        // .required(coverageToolConfig.coveragetool.errorMessage.error0),
        streetNumber: yup.string().test("rangeTest", (coverageToolConfig === null || coverageToolConfig === void 0 ? void 0 : (ref8 = coverageToolConfig.coveragetool) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.errorMessage) === null || ref9 === void 0 ? void 0 : ref9.error1) || " ", function(value) {
            if (value === null || value === void 0 ? void 0 : value.match(/^[1-9][0-9]{0,4}(?:\/)?[a-zA-Z]?$/)) {
                return true;
            }
            return false;
        }).required(coverageToolConfig === null || coverageToolConfig === void 0 ? void 0 : (ref10 = coverageToolConfig.coveragetool) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.errorMessage) === null || ref11 === void 0 ? void 0 : ref11.error0)
    }).required();
};
export var updateContactProblemDataLocal = function(data) {
    if (data === null || data === void 0 ? void 0 : data.checkContactProblem) localStorage.setItem(CONTACT_ME, "1");
    else localStorage.removeItem(CONTACT_ME);
};
/**
 * Method to return the Address info by placeId
 * @param placeId
 */ export var getAddressInfoByPlaceId = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(placeId) {
        var placeDetails, picked, addressString;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return getPlaceId(placeId);
                case 2:
                    placeDetails = _ctx.sent;
                    picked = function(param) {
                        var city = param.city, postalCode = param.postalCode, stateOrProvince = param.stateOrProvince, street = param.street, streetNumber = param.streetNumber;
                        return {
                            city: city,
                            postalCode: postalCode,
                            stateOrProvince: stateOrProvince,
                            street: street,
                            streetNumber: streetNumber
                        };
                    }(placeDetails);
                    addressString = "";
                    Object.values(picked).map(function(el) {
                        if (el) addressString = addressString.concat(el, ", ");
                        return null;
                    });
                    return _ctx.abrupt("return", {
                        placeDetails: _object_spread_props(_object_spread({}, placeDetails), {
                            latitude: (placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.latitude) ? parseFloat(placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.latitude) : 0,
                            longitude: (placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.longitude) ? parseFloat(placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.longitude) : 0
                        }),
                        addressString: addressString
                    });
                case 7:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function getAddressInfoByPlaceId(placeId) {
        return _ref.apply(this, arguments);
    };
}();
/**
 * Method to return the hubPath
 * @param page
 */ export var getHubPath = function(page) {
    var ref, ref1, ref2;
    var hubPath = (page === null || page === void 0 ? void 0 : (ref = page.params) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.product) === null || ref2 === void 0 ? void 0 : ref2.hubPath) || "";
    if (!hubPath) return "";
    var path = "".concat(getHome(), "/").concat(hubPath);
    var product = retrieveProduct();
    var isWinbackProduct = product === null || product === void 0 ? void 0 : product.isWinback;
    if (isWinbackProduct) {
        var cC = product === null || product === void 0 ? void 0 : product.cc;
        var tC = product === null || product === void 0 ? void 0 : product.tc;
        if (cC && tC) return "".concat(path, "?cC=").concat(cC, "&tC=").concat(tC);
        return "".concat(path, "?cC=").concat(cC);
    }
    return hubPath ? "".concat(getHome(), "/").concat(hubPath) : "";
};
/**
 * Method to redirect to product hub
 */ export var goToHubProduct = function() {
    var url = getHome();
    var product = retrieveProduct();
    if (product === null || product === void 0 ? void 0 : product.hubPath) {
        if (product.hubPath.includes("http")) url = product.hubPath;
        else url += "/".concat(product.hubPath);
    }
    window.location.href = "".concat(url);
};
export var setCartCookie = function(product, addressCoverage, technology, numberOfDays, userType) {
    if (!checkWindow()) return;
    var cmsId = (product === null || product === void 0 ? void 0 : product.cmsId) || "";
    var initialCmsId = localStorage.getItem("cms_tool_start");
    var keepInitialCms = initialCmsId && initialCmsId !== "" && initialCmsId !== cmsId;
    var hubPath = localStorage.getItem("hubPath");
    setItemCookie(COOKIE_RETRIEVE_CART, {
        offerId: (product === null || product === void 0 ? void 0 : product.offerId) || "",
        cmsId: keepInitialCms ? initialCmsId : cmsId,
        productId: cmsId,
        address: addressCoverage,
        placeId: localStorage.getItem("placeId") || "",
        offerDetails: {
            redirectUrl: getRedirectCoverage((product === null || product === void 0 ? void 0 : product.redirectCoveragePath) || "") || "",
            isLockinProduct: product === null || product === void 0 ? void 0 : product.isLockInProduct
        },
        hubPath: hubPath,
        technology: technology,
        userType: userType
    }, numberOfDays || 2, false, "privati.vodafone.it");
};
export var retrieveCartCookie = function() {
    if (!checkWindow()) return;
    var cartCookie = getItemCookie(COOKIE_RETRIEVE_CART);
    if (!cartCookie) return;
    return cartCookie;
};
export var deleteCartCookie = function() {
    return setItemCookie(COOKIE_RETRIEVE_CART, "", 0);
};
/**
 * This method is used for delete all cookies
 * add necessary cookies
 */ export var deleteAllCookies = function(ignoreCookies) {
    var cookiesKeys = [
        PARTNER_OFFER_COOKIE,
        COOKIE_CART,
        LOCKIN_OFFER_COOKIE
    ];
    cookiesKeys.forEach(function(el) {
        if (!ignoreCookies || !(ignoreCookies === null || ignoreCookies === void 0 ? void 0 : ignoreCookies.includes(el))) setItemCookie(el, "", 0);
    });
};
/**
 * Get Dxl price by dxl nbo campaign
 * @param dxlNboCampaign
 */ export var getDXLProductPrice = function(dxlNboCampaign) {
    var ref, ref1;
    var dxlNboCampaignPrice = dxlNboCampaign === null || dxlNboCampaign === void 0 ? void 0 : (ref = dxlNboCampaign.cmsCustomization) === null || ref === void 0 ? void 0 : (ref1 = ref.renewalCost) === null || ref1 === void 0 ? void 0 : ref1.price;
    var price = (dxlNboCampaignPrice === null || dxlNboCampaignPrice === void 0 ? void 0 : dxlNboCampaignPrice.toString().replace(/\s/g, "")) || "";
    LoggerInstance.debug("DXL NBO PRICE", price);
    if (!price) {
        return "";
    }
    if (price.includes(".")) {
        var ref2, ref3;
        var splittedPrice = (ref2 = dxlNboCampaignPrice === null || dxlNboCampaignPrice === void 0 ? void 0 : dxlNboCampaignPrice.toString()) === null || ref2 === void 0 ? void 0 : ref2.split("€");
        if (splittedPrice === null || splittedPrice === void 0 ? void 0 : splittedPrice[0]) price = "".concat((ref3 = parseFloat(splittedPrice[0])) === null || ref3 === void 0 ? void 0 : ref3.toFixed(2).replace(".", ","), "€");
    }
    return price;
};
/**
 * Get Dxl price by dxl addon price
 * @param price
 */ export var getDXLAddonProductPrice = function(price) {
    var priceToReturn = 0;
    var updatedPrice = (price === null || price === void 0 ? void 0 : price.toString().replace(/\s/g, "")) || "";
    if (!updatedPrice) {
        return priceToReturn;
    }
    if (updatedPrice.includes(".")) {
        var ref, ref1;
        var splittedPrice = (ref = price === null || price === void 0 ? void 0 : price.toString()) === null || ref === void 0 ? void 0 : ref.split("€");
        if (splittedPrice === null || splittedPrice === void 0 ? void 0 : splittedPrice[0]) priceToReturn = parseFloat((ref1 = parseFloat(splittedPrice[0])) === null || ref1 === void 0 ? void 0 : ref1.toFixed(2));
    }
    return priceToReturn;
};
/**
 * Get Dxl BundlePO
 */ export var getDXLBundlePO = function() {
    var dxlNboCampaign = getFromLocalStorageByKey(DXL_NBO_CAMPAIGN);
    LoggerInstance.debug("DXL NBO", dxlNboCampaign);
    return (dxlNboCampaign === null || dxlNboCampaign === void 0 ? void 0 : dxlNboCampaign.bundlePO) || "";
};
export var getProductCategory = function(offerType) {
    switch(offerType){
        case "2":
        case "fixed":
            return "fixed";
        case "3":
        case "mobile":
            return "mobile";
        case "8":
        case "adv":
            return "adv";
        default:
            return "";
    }
};
export var pushWidgets = function(widgetType, productSliders, globalPromoConfig) {
    var sliders = cloneDeep(productSliders);
    if (sliders.find(function(slider) {
        return slider.type === widgetType;
    }) && (globalPromoConfig === null || globalPromoConfig === void 0 ? void 0 : globalPromoConfig.slides)) {
        var _elements;
        (_elements = sliders.find(function(slider) {
            return slider.type === widgetType;
        }).elements).unshift.apply(_elements, _to_consumable_array(globalPromoConfig.slides));
    }
    return sliders;
};
export var checkPanicMode = function(panicMode, product) {
    var ref;
    if ((product === null || product === void 0 ? void 0 : (ref = product.action) === null || ref === void 0 ? void 0 : ref.type.toString()) === IActionType.CHECKOUT_MODAL_MOBILE.toString()) return (panicMode === null || panicMode === void 0 ? void 0 : panicMode.enablePanicModeMobile) === "true" || false;
    return (panicMode === null || panicMode === void 0 ? void 0 : panicMode.enablePanicModeFixed) === "true" || false;
};
export var overridePromo = function(product, globalPromoConfig) {
    if (product === null || product === void 0 ? void 0 : product.isGlobalPromoActive) {
        var ref;
        return _object_spread(_object_spread_props(_object_spread({}, product), {
            inflactionHub: {
                text: globalPromoConfig === null || globalPromoConfig === void 0 ? void 0 : globalPromoConfig.label,
                popup: globalPromoConfig === null || globalPromoConfig === void 0 ? void 0 : globalPromoConfig.popupDescription,
                color: globalPromoConfig === null || globalPromoConfig === void 0 ? void 0 : globalPromoConfig.color
            },
            inflactionPdp: {
                text: globalPromoConfig === null || globalPromoConfig === void 0 ? void 0 : globalPromoConfig.label,
                popup: globalPromoConfig === null || globalPromoConfig === void 0 ? void 0 : globalPromoConfig.popupDescription,
                color: globalPromoConfig === null || globalPromoConfig === void 0 ? void 0 : globalPromoConfig.color
            },
            inflactionCartView: {
                text: globalPromoConfig === null || globalPromoConfig === void 0 ? void 0 : globalPromoConfig.label,
                popup: globalPromoConfig === null || globalPromoConfig === void 0 ? void 0 : globalPromoConfig.popupDescription,
                color: globalPromoConfig === null || globalPromoConfig === void 0 ? void 0 : globalPromoConfig.color,
                disclaimer: globalPromoConfig === null || globalPromoConfig === void 0 ? void 0 : globalPromoConfig.cartDisclaimer
            }
        }), (globalPromoConfig === null || globalPromoConfig === void 0 ? void 0 : globalPromoConfig.characteristcs) && {
            offerDetails: product === null || product === void 0 ? void 0 : (ref = product.offerDetails) === null || ref === void 0 ? void 0 : ref.concat(globalPromoConfig.characteristcs)
        }, (globalPromoConfig === null || globalPromoConfig === void 0 ? void 0 : globalPromoConfig.detailPageDisclaimer) && {
            pdpDisclaimer: globalPromoConfig.detailPageDisclaimer.concat(product === null || product === void 0 ? void 0 : product.pdpDisclaimer)
        });
    }
    return product;
};
export var overrideAction = function(product, overrideFlag) {
    var getDefaultAction = function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
        var isHomePage = (window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.pathname) === "/";
        if (isHomePage) {
            var ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22;
            return {
                title: (product === null || product === void 0 ? void 0 : (ref8 = product.homepageDetail) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.action) === null || ref9 === void 0 ? void 0 : ref9.title) || (product === null || product === void 0 ? void 0 : (ref10 = product.action) === null || ref10 === void 0 ? void 0 : ref10.title) || "",
                type: (product === null || product === void 0 ? void 0 : (ref11 = product.homepageDetail) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.action) === null || ref12 === void 0 ? void 0 : ref12.type) ? +(product === null || product === void 0 ? void 0 : (ref13 = product.homepageDetail) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.action) === null || ref14 === void 0 ? void 0 : ref14.type) : -1,
                url: (product === null || product === void 0 ? void 0 : (ref15 = product.homepageDetail) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.action) === null || ref16 === void 0 ? void 0 : ref16.url) || "",
                callMeNow: (product === null || product === void 0 ? void 0 : (ref17 = product.homepageDetail) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.action) === null || ref18 === void 0 ? void 0 : ref18.callMeNow) || (product === null || product === void 0 ? void 0 : (ref19 = product.homepageDetail) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.action) === null || ref20 === void 0 ? void 0 : ref20.url) || "",
                localUrl: (product === null || product === void 0 ? void 0 : (ref21 = product.homepageDetail) === null || ref21 === void 0 ? void 0 : (ref22 = ref21.action) === null || ref22 === void 0 ? void 0 : ref22.localUrl) || ""
            };
        }
        return {
            title: (product === null || product === void 0 ? void 0 : (ref1 = product.action) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
            type: (product === null || product === void 0 ? void 0 : (ref2 = product.action) === null || ref2 === void 0 ? void 0 : ref2.type) ? +(product === null || product === void 0 ? void 0 : (ref3 = product.action) === null || ref3 === void 0 ? void 0 : ref3.type) : -1,
            url: (product === null || product === void 0 ? void 0 : (ref4 = product.action) === null || ref4 === void 0 ? void 0 : ref4.url) || "",
            callMeNow: (product === null || product === void 0 ? void 0 : (ref5 = product.action) === null || ref5 === void 0 ? void 0 : ref5.callMeNow) || (product === null || product === void 0 ? void 0 : (ref6 = product.action) === null || ref6 === void 0 ? void 0 : ref6.url) || "",
            localUrl: (product === null || product === void 0 ? void 0 : (ref7 = product.action) === null || ref7 === void 0 ? void 0 : ref7.localUrl) || ""
        };
    };
    var actionProduct = getDefaultAction();
    if (overrideFlag) {
        var ref, ref1, ref2, ref3, ref4, ref5;
        actionProduct = {
            title: (product === null || product === void 0 ? void 0 : (ref = product.panicModeAction) === null || ref === void 0 ? void 0 : ref.title) || "",
            type: (product === null || product === void 0 ? void 0 : (ref1 = product.panicModeAction) === null || ref1 === void 0 ? void 0 : ref1.type) ? +(product === null || product === void 0 ? void 0 : (ref2 = product.panicModeAction) === null || ref2 === void 0 ? void 0 : ref2.type) : IActionType.CALL_ME_NOW,
            url: (product === null || product === void 0 ? void 0 : (ref3 = product.panicModeAction) === null || ref3 === void 0 ? void 0 : ref3.url) || "",
            callMeNow: (product === null || product === void 0 ? void 0 : (ref4 = product.panicModeAction) === null || ref4 === void 0 ? void 0 : ref4.url) || "",
            localUrl: (product === null || product === void 0 ? void 0 : (ref5 = product.panicModeAction) === null || ref5 === void 0 ? void 0 : ref5.url) || ""
        };
    }
    return actionProduct;
};
export var overrideProductList = function(products, panicModeConfiguration, globalPromoConfig) {
    return products.map(function(product) {
        return _object_spread_props(_object_spread({}, overridePromo(product, globalPromoConfig), checkPanicMode(panicModeConfiguration, product) && {
            hubSecondAction: undefined
        }), {
            action: _object_spread({}, overrideAction(product, checkPanicMode(panicModeConfiguration, product)))
        });
    });
};
